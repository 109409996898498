<template>
   <div class="container-fluid" id="job-timeline">
    <div class="row">
       <div class="col-lg-12">
         <div class="row">
           <div class="col-lg-12">
               <div class="card card-block card-stretch">
                  <div class="card-body-2">
                     <div
                       class="
                         mb-2
                         d-flex
                         flex-wrap
                         align-items-center
                         justify-content-between
                       "
                       style="padding: 20px;"
                     >
                       <h5>Workshop Service Timeline
                         <button
                               class="ml-2
                                 btn btn-success btn-sm"
                               @click="refreshClick()"
                             >
                             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 24 24">
                               <path d="M20.944 12.979c-.489 4.509-4.306 8.021-8.944 8.021-2.698 0-5.112-1.194-6.763-3.075l1.245-1.633c1.283 1.645 3.276 2.708 5.518 2.708 3.526 0 6.444-2.624 6.923-6.021h-2.923l4-5.25 4 5.25h-3.056zm-15.864-1.979c.487-3.387 3.4-6 6.92-6 2.237 0 4.228 1.059 5.51 2.698l1.244-1.632c-1.65-1.876-4.061-3.066-6.754-3.066-4.632 0-8.443 3.501-8.941 8h-3.059l4 5.25 4-5.25h-2.92z"/>
                             </svg>
                             Refresh
                         </button>
                       </h5>
                       <div class="d-flex">
                         <div class="ml-2 filter-div">
                           <button
                             class="
                               btn btn-primary btn-sm
                               d-flex
                               align-items-center
                             "
                             @click="filterClick()"
                             v-on:click="showFilters = !showFilters"
                           >
                             <svg
                               xmlns="http://www.w3.org/2000/svg"
                               class="mr-1"
                               width="15"
                               fill="none"
                               viewBox="0 0 24 24"
                               stroke="currentColor"
                             >
                               <path
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                                 stroke-width="2"
                                 d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                               />
                             </svg>
                             <div class="notify">filters
                               <div class="badge"  v-if="countFilter">
                                 <div class="message-count">{{countFilter}}</div>
                               </div>
                             </div>
                           </button>
                         </div>

                         <div class="ml-2">
                           <button
                             class="
                               btn btn-warning btn-sm
                               d-flex
                               align-items-center
                               reset-btn-padding
                             "
                             @click="resetFilters"
                             title="Reset Filters"
                           >
                             <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                               width="15" height="15" viewBox="0 0 1280.000000 1266.000000"
                               preserveAspectRatio="xMidYMid meet">
                               <g transform="translate(0.000000,1266.000000) scale(0.100000,-0.100000)"
                               fill="white" stroke="none">
                               <path d="M6130 12653 c-1136 -47 -2253 -397 -3200 -1002 -1163 -744 -2048
                               -1830 -2525 -3096 -433 -1150 -520 -2406 -249 -3595 169 -740 463 -1430 880
                               -2063 765 -1161 1874 -2034 3189 -2509 977 -354 2044 -467 3080 -328 1388 186
                               2694 829 3675 1810 129 129 1201 1318 1207 1339 2 8 -1951 1746 -1964 1746 -5
                               0 -200 -214 -433 -475 -596 -668 -655 -732 -782 -847 -599 -539 -1284 -857
                               -2083 -964 -292 -39 -726 -36 -1025 7 -1069 153 -1996 719 -2605 1589 -746
                               1066 -863 2457 -305 3632 359 755 979 1375 1745 1744 387 186 728 287 1180
                               351 213 29 712 33 927 5 649 -82 1206 -288 1723 -638 269 -182 481 -372 781
                               -704 153 -168 162 -181 146 -196 -9 -9 -287 -254 -617 -544 -330 -291 -601
                               -532 -603 -537 -2 -4 4 -8 13 -8 8 0 305 -68 658 -150 353 -83 851 -200 1107
                               -260 256 -60 726 -170 1045 -245 319 -75 831 -195 1138 -267 307 -71 560 -128
                               562 -126 3 2 -47 960 -110 2129 -63 1168 -119 2221 -126 2339 -6 118 -14 245
                               -17 282 l-7 67 -520 -459 c-286 -252 -522 -459 -525 -459 -3 -1 -66 67 -140
                               150 -194 219 -425 458 -575 595 -1250 1147 -2934 1759 -4645 1687z"/>
                               </g>
                             </svg>
                           </button>
                         </div>
                       </div>
                     </div>
                  </div>

                  <transition name="slide-fade">
                     <div class="row d-flex pr-5 pl-5" v-if="showFilters">

                       <div class="col-md-3 filter-tabs">
                         <label
                           for="car-make"
                           class="
                             form-label
                             text-uppercase text-muted
                             filter-label-heading
                             mt-3
                           "
                           >Date</label
                         >
                         <b-input-group :class="{'borderColorFilter': scheduledDate !== null ? true : false}">
                           <b-form-datepicker
                             id="scheduled_date_picker"
                             v-model="scheduledDate"
                             size="sm"
                             :class="{ singleOptions: true }"
                             calendar-width="100%"
                             placeholder="Select"
                             @context="onContext"
                             @input="onChangeScheduledDate($event)"
                             :date-format-options="{
                               year: 'numeric',
                               month: 'numeric',
                               day: 'numeric',
                             }"
                           ></b-form-datepicker>
                         </b-input-group>
                       </div>
                       <div class="col-md-3 filter-tabs">
                         <label
                           for="job-status"
                           class="
                             form-label
                             text-muted
                             filter-label-heading
                             text-uppercase
                             mt-3
                           "
                           >{{ jobStatus.length > 0 ? 'Status ('+jobStatus.length+ ') Selected' : 'Status'}}</label
                         >

                         <multiselect
                           id="job-status"
                           v-model="jobStatus"
                           :options="jobStatusArray"
                           label="name"
                           track-by="id"
                           placeholder="Select"
                           :preserve-search="true"
                           @select="onChangejobStatus($event)"
                           @remove="removeStatus($event)"
                           :multiple="true"
                           :show-labels="false"
                           :allow-empty="true"
                           :close-on-select="false"
                           :clear-on-select="false"
                           :class="{ 'borderColorFilter': jobStatus.length > 0 ? true : false, multOptions: true }"

                         >
                         <span class="checkbox-label" slot="option" slot-scope="scope" >
                           <input :key="scope.option.id" :checked="isSelectedStatusCheckbox(scope.option)" class="test" type="checkbox" @focus.prevent/>
                             {{ scope.option.name }}
                             </span>
                         </multiselect>
                       </div>
                       <div class="col-md-3 filter-tabs">
                         <label
                           for="order-workshop"
                           class="
                             form-label
                             filter-label-heading
                             text-muted
                             text-uppercase
                             mt-3
                           "
                           >Workshop</label
                         >
                         <multiselect
                           id="order-workshop"
                           v-model="workshop"
                           :options="workshopArray"
                           :multiple="false"
                           label="name"
                           key="name"
                           track-by="id"
                           placeholder="Type to search"
                           @select="onChangeWorkshop"
                           @remove="removeWorkshop"
                           @open="handleWorkshop"
                           @search-change="asyncFindWorkshop"
                           :local-search="false"
                           :internal-search="false"
                           :searchable="true"
                           :preserve-search="true"
                           :show-labels="false"
                           :allow-empty="true"
                           :clear-on-select="false"
                           :close-on-select="true"
                           :class="{'borderColorFilter': workshop !== null ? true : false, singleOptions: true}"
                         >
                           <template slot="clear" slot-scope="props">
                              <span class="custom__tag assigned-to-custom">
                              <span class="custom__remove" v-if="workshop !== null" @click="removeWorkshop(props)">x</span>
                              </span>
                            </template>
                         </multiselect>
                       </div>
                     </div>
                  </transition>

                   <div class="row p-3 color-define">
                     <div class="col-md-2">
                       <svg
                         width="18"
                         height="18"
                         fill="#db7e069e"
                         clip-rule="evenodd"
                         fill-rule="evenodd"
                         stroke-linejoin="round"
                         stroke-miterlimit="2"
                         viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <path
                           d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1z"
                           fill-rule="nonzero"
                         />
                       </svg>
                       -
                       <span>
                         Assigned
                       </span>
                     </div>
                     <div class="col-md-2">
                       <svg
                         width="18"
                         height="18"
                         fill="#88888899"
                         clip-rule="evenodd"
                         fill-rule="evenodd"
                         stroke-linejoin="round"
                         stroke-miterlimit="2"
                         viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <path
                           d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1z"
                           fill-rule="nonzero"
                         />
                       </svg>
                       -
                       <span>
                         Unassigned
                       </span>
                     </div>
                     <div class="col-md-2">
                       <svg
                         width="18"
                         height="18"
                         fill="#3378ff8c"
                         clip-rule="evenodd"
                         fill-rule="evenodd"
                         stroke-linejoin="round"
                         stroke-miterlimit="2"
                         viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <path
                           d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1z"
                           fill-rule="nonzero"
                         />
                       </svg>
                       -
                       <span>
                         In-Progress
                       </span>
                     </div>
                     <div class="col-md-2">
                       <svg
                         width="18"
                         height="18"
                         fill="#3cb72c75"
                         clip-rule="evenodd"
                         fill-rule="evenodd"
                         stroke-linejoin="round"
                         stroke-miterlimit="2"
                         viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <path
                           d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1z"
                           fill-rule="nonzero"
                         />
                       </svg>
                       -
                       <span>
                         Completed
                       </span>
                     </div>
                     <div class="col-md-2">
                       <svg
                         width="18"
                         height="18"
                         fill="#fd040487"
                         clip-rule="evenodd"
                         fill-rule="evenodd"
                         stroke-linejoin="round"
                         stroke-miterlimit="2"
                         viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <path
                           d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1z"
                           fill-rule="nonzero"
                         />
                       </svg>
                       -
                       <span>
                         Cancelled
                       </span>
                     </div>

                     <div class="d-flex col-md-2" style="border: 1px solid; border-radius: 10px; justify-content: space-between">
                       <div>
                         Total Count :
                       </div>
                       <div>
                         {{this.totalRows}}
                       </div>
                     </div>
                   </div>
                   <div>
                     <v-sheet>
                       <div class="d-flex mb-2 justify-content-center" style="margin-left: 40px;">
                         <button
                           class="
                             ml-2
                             btn btn-outline-primary btn-sm
                             text-primary
                             d-flex
                             align-items-center
                             reset-btn-padding
                           "
                           @click="prevDate()"
                           outlined
                           title="Reset Filters"
                         ><v-icon class="pre-next-icon">mdi-chevron-left</v-icon> Previous day
                         </button>
                         <button
                           class="
                             ml-2
                             btn btn-outline-primary btn-sm
                             text-primary
                             d-flex
                             align-items-center
                             reset-btn-padding
                           "
                           @click="nextDate()"
                           title="Reset Filters"
                         > Next day<v-icon class="pre-next-icon">mdi-chevron-right</v-icon>
                         </button>
                       </div>
                       <v-calendar
                         ref="calendar"
                         v-model="selectedDate"
                         :weekdays="weekday"
                         :type="typeValue"
                         :events="events"
                         :event-overlap-mode="mode"
                         :event-overlap-threshold="30"
                         :event-color="getEventColor"
                         :day-format="dayFormat"
                         :interval-height=48
                         first-interval="7"
                         color="date-header"
                         intervalMinutes="60"
                         interval-count="16"
                       >
                         <template #event="{ event }">
                           <div style="display:flex;" :id="'calendar_div_' + event.id" @click="onCalendarClick(event)">
                             <div class="event-name">
                               {{ event.orderId }} -
                               {{ event.assignedTo ? event.assignedTo : 'Unassigned' }} -
                               {{ event.car }} -
                               {{ event.status }}
                             </div>
                           </div>
                         </template>
                       </v-calendar>
                     </v-sheet>
                     <b-popover
                       v-for="event in events"
                       :key="event.id"
                       :target="'calendar_div_' + event.id"
                       placement="bottom"
                       triggers="hover"
                     >
                       <div>
                         Order: {{ event.orderId }}
                       </div>
                       <div v-if="event.assignedTo">
                         Assigned To: {{ '  ' + event.assignedTo }}
                       </div>
                       <div>
                         Car: {{ event.car }}
                       </div>
                       <div>
                         Status: {{ event.status }}
                       </div>
                     </b-popover>
                   </div>
                   <!-- Calendar Modal -->
                 <b-modal
                   v-model="calendarModalShow"
                   id="modal-center"
                   centered
                   hide-footer
                   title="Workshop Service Job"
                   size="md"
                 >
                   <div class="table-responsive">
                     <table class="table table-borderless mb-0">
                       <tbody>
                         <tr class="white-space-no-wrap">
                           <td class="text-muted pl-0">Order Number</td>
                           <td class="order-id">
                             <div
                               class="
                                 d-flex
                                 align-items-center
                               "
                               title="Click to view order details"
                             >
                               <router-link
                                 :to="'order/' + this.orderId"
                                 class=""

                               >
                                 {{ this.orderId }}
                               </router-link>
                             </div>
                           </td>
                         </tr>
                         <tr class="white-space-no-wrap">
                           <td class="text-muted pl-0">Status</td>
                           <td class="order-id">
                             {{ this.status | customTitleCase }}
                           </td>
                         </tr>
                         <tr class="white-space-no-wrap">
                           <td class="text-muted pl-0">Car</td>
                           <td class="order-id">
                             {{ this.car }}
                           </td>
                         </tr>
                         <tr class="white-space-no-wrap">
                           <td class="text-muted pl-0">Assigned To</td>
                           <td class="order-id">
                             {{ this.assignedTo ? this.assignedTo : 'Unassigned' }}
                           </td>
                         </tr>
                         <tr class="white-space-no-wrap">
                           <td class="text-muted pl-0">Scheduled At</td>
                           <td class="order-id" :title="this.scheduledAt">
                             {{ this.scheduledAt | DateTimeFormat }}
                           </td>
                         </tr>
                         <tr class="white-space-no-wrap">
                           <td class="text-muted pl-0">Scheduled Upto</td>
                           <td class="order-id" :title="this.scheduledUpto">
                             {{ this.scheduledUpto | DateTimeFormat }}
                           </td>
                         </tr>
                       </tbody>
                     </table>
                   </div>
                 </b-modal>
               </div>
           </div>
         </div>
       </div>
     </div>
   </div>
</template>

<script>
// import calendar from "../../../components/calendar/calendar.vue";
import { GET_WORKSHOP_JOBS, MY_WORKSHOPS } from "../../../graphql/query";
import moment from 'moment';
import "@fortawesome/fontawesome-free/css/all.css";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import { mapActions, mapGetters } from 'vuex';
export default {
 name: "job-timeline",
 data(){
   return{
     typeValue: 'day',
     mode: 'column',
     weekday: [0, 1, 2, 3, 4, 5, 6],
     selectedDate: null,
     events: [],
     jobSelected: null,
     jobId: "",
     showFilters: false,
     dashboardJobs: [],
     dashboardJobTimeline: [],
     excludeCancelled: true,
     totalRows: 1,
     currentPage: 1,
     perPage: 10,
     after: null,
     before: null,
     hasNextPage: true,
     hasPreviousPage: false,
     last: null,
     first: null,
     direction: "",
     field: "",
     type: null,
     typeData:null,
     status: null,
     statusData: null,
     countFilter:0,
     assignedToArray: [],
     assignedToData: null,
     assignedToValue: null,
     assignedUser: [],
     searchAssignedTo: "",
     workshop: null,
     workshopArray: [],
     searchWorkshop: "",
     jobStatus: [],
     jobStatusId: [],
     jobStatusArray: [
       {id: "ASSIGNED", name:"Assigned"},
       {id: "ACCEPTED", name:"Accepted"},
       {id: "STARTED", name:"Started"},
       {id: "PAUSED", name:"Paused"},
       {id: "RESUMED", name:"Resumed"},
       {id: "COMPLETED", name:"Completed"},
       {id: "CANCELLED", name:"Cancelled"},
       {id: "AWAITING_PARTS", name:"Awaiting Parts"},
       {id: "DELIVERED", name:"Delivered"},
       {id: "INSPECTED", name:"Inspected"},
       {id: "ORD_SVC_STARTED", name:"Ordered Svc Started"},
       {id: "ORDERED", name:"Ordered"},
       {id: "QUOTE_ACCEPTED", name:"Quote Accepted"},
       {id: "QUOTE_REJECTED", name:"Quote Rejected"},
       {id: "QUOTED", name:"Quoted"},
       {id: "RECEIVED", name:"Received"},
       {id: "REJECTED", name:"Rejected"},
       {id: "RETURNED", name:"Returned"},
       {id: "TESTING", name:"Testing"},
       {id: "UNASSIGNED", name:"Unassigned"},
       {id: "WORK_IN_PROGRESS", name:"Work In Progress"},
     ],
     jobStatusData: [],
     calendarModalShow: false,
     orderId: null,
     car: null,
     start: null,
     end: null,
     assignedTo: null,
     scheduledAt: null,
     scheduledUpto: null,
     displayWorkshop: null,
     scheduledDate: null,
     jobTimelineFilters: {
       jobStatusData:[],
       workshop: null,
       scheduledDate: null,
     }
   }
 },
 computed: {
   ...mapGetters({
     stateJobTimelineFilters: 'jobTimelineFilters',
   }),
   popoverContent() {
     return `
       ${this.events.map(event => `
         <p>Assigned To: ${event.assignedTo}</p>
         <p>Type: ${event.type}</p>
         <hr>
       `).join('')}
     `;
   }
 },
 async created(){
   this.first = this.perPage;
   this.jobTimelineFilters = this.stateJobTimelineFilters;
   this.changeJobTimelineFilters(this.stateJobTimelineFilters);
   (this.direction = "ASC"), (this.field = "SCHEDULED_AT");
   this.scheduledDate = new Date();
   this.selectedDate = this.formatDate(this.scheduledDate);
   if (this.jobTimelineFilters) {
     this.jobTimelineFilters.sort_by = this.field +' - '+this.direction;
   }
   this.getLocalStorage();
   this.getJobs();
 },
 methods:{
   ...mapActions({
     jobTimelineFilterChange: 'jobTimelineFilterAction',
     searchChange: 'searchAction'
   }),
   dayFormat({ date }) {
     return moment(String(date)).format('ddd, DD MMM')
   },
   firstInterval(){
     return "06:00"
   },
   getIcon() {
     return 'mdi-alert';
   },
   onCalendarClick(event){
     this.calendarModalShow = true;
     this.orderId = event.orderId;
     this.car = event.car;
     this.start = event.start;
     this.end = event.end;
     this.scheduledAt = event.scheduledAt;
     this.scheduledUpto = event.scheduledUpto;
     this.assignedTo = event.assignedTo;
     this.status = event.status;
     this.displayWorkshop = event.displayWorkshop;
   },
   getEventColor(event) {
     return event.color;
   },
   filterClick(){
     this.countFilter = document.querySelectorAll('.borderColorFilter').length;
     if(this.jobTimelineFilters.scheduledDate){
       this.scheduledDate = this.jobTimelineFilters.scheduledDate;
     }
   },
   changeJobTimelineFilters(value) {
     this.countFilter = 0;
     this.jobTimelineFilterChange({ jobTimelineFilters: value });
   },
   getLocalStorage(){
      this.countFilter = 0;
      var value = JSON.parse(localStorage.getItem("vuex"));
      value = value.jobTimelineFilters;
      if(value){
       this.selectedDate = this.formatDate(new Date());
       if(
           value.scheduledDate !== null ||
           value.jobStatusData.length > 0 ||
           value.workshop !== null) {

           this.showFilters = true;

           if(value.scheduledDate){
             this.scheduledDate = this.formatDate(value.scheduledDate);
             this.selectedDate = value.scheduledDate;
             this.countFilter += 1;
           }

           if(value.workshop){
             this.workshop = value.workshop;
             this.countFilter += 1;
           }

           if(value.jobStatusData.length>0){
             value.jobStatusData.map(res =>{
               this.jobStatusId.push(res.id);
               this.jobStatus.push(res);
               var data =  document.getElementsByClassName("multiselect__tag");
                 for (var i = 0; i < data.length; i++) {
                   var html = '';
                   html += '<span>'+this.jobStatus[i].name+'</span> <i aria-hidden="true" tabindex="'+this.jobStatus[i].id+'" class="multiselect__tag-icon"></i>'
                   data[i].innerHTML = html
                 }
             });
              this.countFilter += 1;
           }

           this.showFilters = false;
       }
       else{
         this.showFilters = false;
       }
     }
   },
   onChangeScheduledDate(event) {
     this.selectedDate = this.formatDate(event);
     this.after = null;
     this.before = null;
     this.currentPage = 1;
     this.jobTimelineFilters.scheduledDate = event;
     this.getJobs();
   },
   prevDate(){
     // this.$refs.calendar.prev();
     const currentDate = new Date(this.selectedDate);
     currentDate.setDate(currentDate.getDate() - 1);
     this.onChangeScheduledDate(currentDate);
   },
   nextDate(){
     // this.$refs.calendar.next();
     const currentDate = new Date(this.selectedDate);
     currentDate.setDate(currentDate.getDate() + 1);
     this.onChangeScheduledDate(currentDate);
   },
   refreshClick(){
     this.first = this.perPage;
     this.last = null;
     this.before = null;
     this.after = null;
     this.currentPage = 1;
     this.getJobs();
   },

   onContext(ctx) {
     this.nextServiceDue = ctx.selectedYMD;
   },
   async getJobs() {
     if(this.selectedDate == null) {
       this.selectedDate = this.formatDate(new Date());
     }
     await this.$apollo
       .query({
         query: GET_WORKSHOP_JOBS,
         variables: {
           first: 100,
           after: null,
           before: null,
           search: this.search,
           direction: this.direction,
           field: this.field,
           last: null,
           type: "WORKSHOP_SERVICE",
           workshop: this.workshop ? this.workshop.id : null,
           status: this.jobStatusId,
           scheduledAt: {lte: this.selectedDate},
           scheduledUpto: {gte: this.selectedDate},
         },
       })
       .then((data) => {
         let wjobs = data.data.workshopJobs;
         this.dashboardJobs = wjobs.edges;
         if (this.jobStatusId.includes("CANCELLED")) {
           this.excludeCancelled = false;
           this.dashboardJobTimeline = this.dashboardJobs;
         } else {
           this.excludeCancelled = true;
           this.dashboardJobTimeline = this.dashboardJobs.filter(
             edge => edge.node.status !== "CANCELLED"
           );
         }
         this.totalRows = this.dashboardJobTimeline.length;
         this.after = wjobs.pageInfo.endCursor;
         this.before = wjobs.pageInfo.startCursor;
         this.hasNextPage = wjobs.pageInfo.hasNextPage;
         this.hasPreviousPage = wjobs.pageInfo.hasPreviousPage;
       });
       this.getEvents();
   },
   getEvents(){
     const events = [];
     let selDate = new Date(this.selectedDate);
     for (let i = 0; i < this.dashboardJobTimeline.length; i++) {
       var startTime = new Date(this.dashboardJobTimeline[i].node.scheduledAt);
       if (startTime < selDate) {
         startTime.setDate(selDate.getDate());
         startTime.setHours(0, 0, 0, 0);
       }
       var endTime = new Date(this.dashboardJobTimeline[i].node.scheduledUpto);
       if (endTime.getDate > selDate) {
         endTime.setDate(selDate.getDate());
         endTime.setHours(23, 59, 59, 0);
       }
       events.push({
         id: this.dashboardJobTimeline[i].node.id,
         orderId: this.dashboardJobTimeline[i].node.order.number,
         displayWorkshop: this.dashboardJobTimeline[i].node.order.workshop ? this.dashboardJobTimeline[i].node.order.workshop.name : null,
         start: startTime,
         end: endTime,
         scheduledAt: this.dashboardJobTimeline[i].node.scheduledAt,
         scheduledUpto: this.dashboardJobTimeline[i].node.scheduledUpto,
         status: this.dashboardJobTimeline[i].node.status,
         car: this.dashboardJobTimeline[i].node.order.car.model.make.name + ' ' + this.dashboardJobTimeline[i].node.order.car.model.name,
         assignedTo: this.dashboardJobTimeline[i].node.assignedTo ?
         this.dashboardJobTimeline[i].node.assignedTo.firstName + ' ' + this.dashboardJobTimeline[i].node.assignedTo.lastName
         : "",
         color:
           this.dashboardJobTimeline[i].node.isCompleted === true
             ? "#3cb72c75"
             : this.dashboardJobTimeline[i].node.status === "ASSIGNED"
             ? "#db7e069e"
             : this.dashboardJobTimeline[i].node.status === "UNASSIGNED"
             ? "#88888899"
             : this.dashboardJobTimeline[i].node.status === "CANCELLED"
             ? "#fd040487" : "#3378ff8c",
         timed: true,
       })
     }

     this.events = events;
   },
   onChangeExcludeCancelled(event) {
     this.excludeCancelled = event;
     if(this.excludeCancelled){
       this.dashboardJobTimeline = this.dashboardJobs.filter(
         edge => edge.node.status !== "CANCELLED"
       );
     } else {
       this.dashboardJobTimeline = this.dashboardJobs;
     }
     this.getEvents();
   },
   formatDate(date) {
     var d = new Date(date),
     month = "" + (d.getMonth() + 1),
     day = "" + d.getDate(),
     year = d.getFullYear();

     if (month.length < 2) month = "0" + month;
     if (day.length < 2) day = "0" + day;

     return [year, month, day].join("-");
   },
   onChangejobStatus(selectedOption) {
     this.jobStatusId.push(selectedOption.id);
     this.jobTimelineFilters.jobStatusData.push(selectedOption);
     this.after = null;
     this.before = null;
     this.currentPage = 1;
     this.changeJobTimelineFilters(this.jobTimelineFilters);
     this.getJobs();
   //   this.jobStatus = [];
   },
   isSelectedStatusCheckbox(option){
     return this.jobStatus.some((op) => op.id === option.id)
   },
   removeStatus(selectedOption) {
     // this.categoryId.splice(selectedOption.id, 1);
     this.jobStatusId = this.jobStatusId.filter(item => item !== selectedOption.id);
     this.jobTimelineFilters.jobStatusData = this.jobTimelineFilters.jobStatusData.filter(item => item.id !== selectedOption.id);
     this.changeJobTimelineFilters(this.jobTimelineFilters);
     this.before = null;
     this.after = null;
     this.currentPage = 1;
     this.getJobs();
   },
   //ASSIGNED TO FILTER
   customLabelAssignedTo(opt) {
     if (opt.firstName) {
       return opt.firstName + " " + opt.lastName;
     }
   },
   handleSearchAssignedTo(event) {
     if (event) {
       this.searchAssignedTo = event;
       this.handleAssignedTo();
     }
   },
   // WORKSHOP METHODS
   onChangeWorkshop(selectedOption) {
     this.workshop = selectedOption;
     this.jobTimelineFilters.workshop = selectedOption;
     this.after = null;
     this.before = null;
     this.currentPage = 1;
     this.changeJobTimelineFilters(this.jobTimelineFilters);
     this.getJobs();
   },
   isSelectedWorkshopCheckbox(option){
     return this.workshop.id === option.id;
   },
   handleWorkshop() {
     this.$apollo
       .query({
         query: MY_WORKSHOPS,
         variables: {
           first: this.first,
           search: this.searchWorkshop,
         },
       })
       .then((data) => {
         this.workshopArray = data.data.myWorkshops;
       });
   },
   asyncFindWorkshop(query) {
     this.searchWorkshop = query;
     this.handleWorkshop();
   },
   removeWorkshop() {
     this.workshop = null;
     this.jobTimelineFilters.workshop = null;
     this.changeJobTimelineFilters(this.jobTimelineFilters);
     this.before = null;
     this.after = null;
     this.currentPage = 1;
     this.getJobs();
   },
   resetFilters() {
     this.currentPage = 1;
     this.after = null;
     this.before = null;
     this.first = this.perPage;
     (this.last = null),
     (this.sorting = null),
     (this.statusData = null),
     (this.direction = "ASC"),
     (this.field = "SCHEDULED_AT"),
     this.typeData = null;
     this.search = null,
     this.category = [],
     this.categoryId = [],
     this.workshop = null,
     this.country = null;
     this.jobStatus = [];
     this.assignedToData = null,
     this.assignedToValue = null,
     this.checkedIsStaff = false;
     this.orderCategory = [];
     this.jobTimelineFilters.workshop = null;
     this.jobTimelineFilters.jobStatusData = [];
     localStorage.removeItem("vuex");
     this.countFilter = 0;
     this.getJobs().then(() => {
       location.reload();
     });
   }
 },
};
</script>

<style>
.v-event-timed{
 /* font-weight: 600 !important; */
 padding: 7px !important;
 border-color: white !important;
 font-size: 15px !important;
 /* min-width: 10%; */
}
/* .v-calendar-daily__pane{
 width: 300% !important;
} */
.color-define{
 font-size: 18px;
}
.event-name{
 padding-top:6px;
 padding-left: 10px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: normal;
}
.event-img{
 padding-top:5px;
 padding-left: 4px
}
.img-size{
 width: 20px;
}
.v-calendar .v-event-timed{
 border-radius: 10px !important;
}
.event-container {
 position: relative;
}
.theme--light.v-calendar-events .v-event-timed{
 border: 2px solid #fff!important;
}
#scheduled_date_picker.form-control, #lte_datetime_picker.form-control{
 height:32px !important;
 /* padding: 0.625rem 1rem !important; */
}
.filter-cross-btn {
 display: flex;
 justify-content: center;
 align-items: center;
 border: none;
 padding: 0 0.75rem 0 0.75em;
 border-radius: 0.25rem;
}

.filter-label-heading {
   font-weight: 600;
   /* font-size: 0.9rem; */
   font-size: 12px;
   text-transform: capitalize !important;
}

.popover-content {
 position: absolute;
 top: calc(100% + 10px);
 left: 0;
 background-color: white;
 padding: 10px;
 box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
 border-radius: 4px;
}
.pre-next-icon{
 color: #3378FF !important;
}
.btn-outline-primary:hover{
 color: #fff !important;
}
.btn-outline-primary:hover .pre-next-icon{
 color: #fff !important;
}
.multOptions .multiselect__tags {
   height: 55px;
   overflow-x: hidden;
   overflow-y: scroll;
}

.filter-label-heading {
 font-weight: 600;
 font-size: 12px;
 text-transform: capitalize !important;
}
.v-calendar-daily_head-day .v-present .v-past .v-future{
 font-weight: 700 !important;
}
.v-calendar-daily_head-weekday{
 display: none;
}
.v-calendar-daily_head-day-label{
 padding: 10px !important;
}
.v-calendar-daily_head-day-label .v-btn{
 cursor: none;
 pointer-events: none;
 width: 25%  !important;
 border-radius: 5px !important;
 color: black  !important;
 font-weight: 800 !important;
}
.v-sheet{
 padding-top: 12px;
}
#job-timeline .singleOptions .multiselect__placeholder {
   color: #adadad;
   display: inline-block;
   padding-left: 5px;
   min-height: 12px !important;
   line-height: 12px !important;
   margin-bottom: 7px;
}
#job-timeline .borderColorFilter{
 /* background-color: gray; */
 border: 2px solid #9a18ffd1 !important;
     border-radius: 5px;

}

.notify .badge {
 background: radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
 background: -moz-radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
 background: -ms-radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
 background: -o-radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
 background: -webkit-radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
 background-color: #ed2324;
 border-radius: 50%;
 width: 20px;
 height: 20px;
 text-align: center;
 padding: 5% 5%;
 float: right;
 display: block;
 position: relative;
 top: -16px;
 left: 12px;
}
.message-count {
 position: relative;
 display: block;
 font: bold 14px/13px Helvetica, Verdana, Tahoma;
 text-align: center;
 margin: 0;
 top: 2px;
}

.assigned-to-custom{
 font-size: 20px;
 cursor: pointer;
 position: absolute;
 top: 40%;
 right: 20%;
 z-index: 1;
 transform: translate(0%, -50%);
}

@media only screen and (max-width: 417px) {
 .sorting-div{
   margin-bottom: 1rem;
 }
 .filter-div{
   margin-left: 0rem !important;
 }

}
@media only screen and (max-width: 768px) {
 .customMultiSelect-div{
   max-width:100%;
   flex: 0 0 100%;
 }
 .event-name{
   padding-top:0px;
   padding-left: 0px;
   width: 110px;
   overflow: hidden;
   text-overflow: ellipsis;
   font-size: 12px;
 }
 .event-img{
   display: none;
 }
 .date-header{
   width: 70% !important;
 }
 .color-define{
   font-size: 14px;
 }
.v-calendar-daily_head-day-label .v-btn{
 width: 65% !important;
}

}
</style>